<template>
  <div v-if="unvalidLead" class="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
    <div class="text-center">
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Ce lien n'est plus valide</h1>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <a
          href="mailto:contact@estaly.co"
          class="rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary text-button-font-primary bg-primary uppercase"
        >
          Contactez le support
          <span aria-hidden="true">&rarr;</span>
        </a>
      </div>
    </div>
  </div>
  <div v-else class="bg-soft-gray">
    <div v-if="offerPrices">
      <div v-if="availableDiscount" class="fixed top-0 w-full z-10">
        <Discount :discount="lead.discount" />
      </div>
      <div :class="[availableDiscount ? 'pt-20 md:pt-16' : '', 'flex justify-between px-4 py-12 sm:p-12']">
        <div>
          <img :src="brokerLogoUrl" class="h-14" />
        </div>
        <img :src="logoUrl" class="h-12" />
      </div>
    </div>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" v-if="offerPrices">
      <div class="mx-auto max-w-4xl">
        <div class="flex gap-4">
          <p
            class="px-4 text-6xl text-gray-900 font-heading-1 tracking-tighter font-bold sm:text-7xl"
            style="line-height: 80%"
          >
            {{ marketing.headline }}
          </p>
        </div>
        <Marketing :marketing="marketing" />
        <MarketingDetails :marketingDetails="marketingDetails" :productPrice="productPrice" />

        <div id="payment" ref="paymentElement">
          <RadioGroup v-model="selectedContractSku">
            <RadioGroupLabel class="text-2xl font-bold">
              <div class="pt-12">Choisissez votre durée de couverture</div>
            </RadioGroupLabel>

            <div class="flex gap-4 mt-4 flex-col sm:flex-row">
              <RadioGroupOption
                as="template"
                v-for="offerPrice in offerPrices"
                :key="offerPrice.contractSku"
                :value="offerPrice.contractSku"
                v-slot="{ checked, active }"
              >
                <div
                  :class="[
                    checked ? 'border-transparent border-primary' : 'border-gray-300',
                    active ? 'border-primary ring-2 ring-primary' : '',
                    'relative overflow-hidden flex border bg-white p-4 shadow-sm focus:outline-none flex-grow rounded-3xl cursor-pointer',
                  ]"
                >
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <RadioGroupLabel as="span" class="block text-md font-medium text-gray-900">
                        {{ offerPriceDisplayTermLength(offerPrice) }}
                      </RadioGroupLabel>
                      <RadioGroupDescription as="span" class="text-gray-500 mt-1 flex items-center text-md">
                        <div v-if="monthDiscount && offerPrice.recurring">
                          <span class="text-primary-dark text-sm">
                            {{ lead.discount.finalPrice }} pendant {{ lead.discount.offeredMonths }} mois*
                          </span>
                        </div>
                        <div v-else>
                          {{ offerPriceDisplayPrice(offerPrice) }}
                          <div class="w-32 absolute top-6 transform rotate-45 bg-primary-light" style="right: -8px">
                            <span
                              class="text-primary-dark text-sm"
                              style="padding-left: 22px"
                              v-if="offerPrice.termLength > 24 && offerPrices.length > 1"
                            >
                              Remise de {{ offerPriceComputeReduction(offerPrices, offerPrice) }}%
                            </span>
                          </div>
                          <span
                            class="line-through text-primary font-light text-sm ml-1"
                            v-if="offerPrice.termLength > 24 && offerPrices.length > 1"
                          >
                            {{ offerPriceDisplayReduction(offerPrices, offerPrice) }}
                          </span>
                        </div>
                      </RadioGroupDescription>
                    </span>
                  </span>
                  <CheckCircleIcon
                    :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary-dark']"
                    aria-hidden="true"
                  />
                  <div v-if="monthDiscount && offerPrice.recurring">
                    <div class="w-32 absolute top-6 transform rotate-45 bg-primary-light" style="right: -8px">
                      <span class="pr-4 pl-8 ml-1 text-primary-dark text-sm">Promotion</span>
                    </div>
                  </div>
                  <span
                    :class="[
                      active ? 'border' : 'border-2',
                      checked ? 'border-primary' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-3xl cursor-pointer',
                    ]"
                    aria-hidden="true"
                  />
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div v-if="monthDiscount && monthlyPrice">
          <span class="mt-1 text-gray-500 text-sm">* puis {{ formatPrice(monthlyPrice) }}/mois</span>
        </div>

        <div class="mt-12">
          <p class="text-2xl font-bold">Complétez vos informations</p>
          <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema" as="div">
            <form @submit="handleSubmit($event, handlePayment)">
              <div class="grid grid-cols-6 gap-6 mt-4">
                <div class="col-span-6 sm:col-span-3">
                  <label for="LeadFirstName" class="block text-sm text-gray-600">Prénom</label>
                  <Field
                    type="text"
                    name="LeadFirstName"
                    id="LeadFirstName"
                    autocomplete="given-name"
                    class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    placeholder="John"
                    v-model="lead.firstName"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="LeadLastName" class="block text-sm text-gray-600">Nom</label>
                  <Field
                    type="text"
                    name="LeadLastName"
                    id="LeadLastName"
                    autocomplete="family-name"
                    class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    placeholder="Doe"
                    v-model="lead.lastName"
                  />
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <label for="LeadEmail" class="block text-sm text-gray-600">Adresse email</label>
                  <Field
                    type="text"
                    name="LeadEmail"
                    id="LeadEmail"
                    autocomplete="email"
                    class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    placeholder="example@gmail.com"
                    v-model="lead.email"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="LeadPhone" class="block text-sm text-gray-600">Numéro de téléphone</label>
                  <Field
                    type="text"
                    name="LeadPhone"
                    id="LeadPhone"
                    autocomplete="tel"
                    class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    placeholder="+ 33 6 10 10 10 10"
                    v-model="lead.phone"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="LeadBirthDate" class="block text-sm text-gray-600">Date de naissance</label>
                  <Field
                    type="date"
                    name="LeadBirthDate"
                    id="LeadBirthDate"
                    autocomplete="birth-date"
                    class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    placeholder="01/01/1970"
                    v-model="lead.birthDate"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="LeadBirthCity" class="block text-sm text-gray-600">Ville de naissance</label>
                  <Field
                    type="text"
                    name="LeadBirthCity"
                    id="LeadBirthCity"
                    class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    placeholder="Paris"
                    v-model="lead.birthCity"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label for="LeadBirthCountry" class="block text-sm text-gray-600">Pays de naissance</label>
                  <Field
                    type="text"
                    name="LeadBirthCountry"
                    id="LeadBirthCountry"
                    class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    placeholder="France"
                    v-model="lead.birthCountry"
                  />
                </div>

                <GoogleAddress :initialAddress="lead.address" @newAddress="newLeadAddress" />

                <div class="col-span-6 sm:col-span-3">
                  <label for="AddressComplement" class="block text-sm text-gray-600">Complément d'adresse</label>
                  <Field
                    type="text"
                    name="AddressComplement"
                    id="AddressComplement"
                    autocomplete="text"
                    class="mt-1 block w-full rounded-2xl border-gray-400 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
                    placeholder="Résidence, Etage..."
                    v-model="lead.address.other"
                  />
                </div>
              </div>

              <div class="mt-8">
                <StripePayment v-if="selectedOfferPrice" :offerPrice="selectedOfferPrice" :lead="lead" />
              </div>
              <CustomerAgreement :contractDetailsLink="contractDetailsLink" />
            </form>
          </VeeForm>
        </div>
      </div>
    </div>

    <div class="bg-gray-100 py-12 sm:p-12 mt-8" v-if="offerPrices && !lead.thirdPartyManaged">
      <Footer />
    </div>
    <div class="flex justify-center" v-if="!offerPrices">
      <Loading />
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/outline";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { Form as VeeForm, Field } from "vee-validate";
import LeadService from "../api/services/lead";
import StripePayment from "@/components/StripePayment";
import Marketing from "@/components/subscription/Marketing";
import MarketingDetails from "@/components/subscription/MarketingDetails";
import Loading from "@/components/subscription/Loading";
import Footer from "@/components/subscription/Footer";
import CustomerAgreement from "@/components/subscription/CustomerAgreement";
import GoogleAddress from "@/components/GoogleAddress";
import Discount from "@/components/subscription/Discount";
import { isObjectValid } from "@/utils/validation";
import { formatPrice } from "@estaly/ui/src/utils/price_formatter";

export default {
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    VeeForm,
    Field,
    CheckCircleIcon,
    StripePayment,
    Marketing,
    MarketingDetails,
    Loading,
    Footer,
    CustomerAgreement,
    GoogleAddress,
    Discount,
  },
  data() {
    return {
      offer: {},
      lead: {
        status: "",
        firstName: "",
        lastName: "",
        birthDate: "",
        birthCity: "",
        birthCountry: "",
        email: "",
        phone: "",
        address: {
          street: "",
          city: "",
          province: "",
          country: "",
          zipCode: "",
          other: "",
        },
        documents: "",
        discount: undefined,
      },
      logoUrl: "",
      brokerLogoUrl: "",
      offerPrices: "",
      selectedContractSku: "",
      marketing: "",
      marketingDetails: "",
      proceedToPayment: false,
      unvalidLead: false,
    };
  },
  created() {
    this.getLeadInformations();
  },

  watch: {
    offerPrices(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.scrollToPayment();
        });
      }
    },
  },

  computed: {
    monthlyPrice() {
      return this.offerPrices.find((offerPrice) => offerPrice.recurring === true)?.price;
    },
    selectedOfferPrice() {
      if (this.selectedContractSku) {
        return this.offerPrices.filter((offerPrice) => offerPrice.contractSku === this.selectedContractSku)[0];
      } else {
        return this.offerPrices[0];
      }
    },
    converted() {
      return this.lead.status === "converted";
    },
    contractDetailsLink() {
      return this.marketingPage;
    },
    availableDiscount() {
      return isObjectValid(this.lead.discount.title);
    },
    monthDiscount() {
      return isObjectValid(this.lead.discount.type) ? this.lead.discount.type.split("_")[0] === "months" : false;
    },
  },

  methods: {
    formatPrice,
    scrollToPayment() {
      const paymentElement = this.$refs.paymentElement;
      if (this.$route.query.scrollToPayment === "true") {
        if (paymentElement) {
          paymentElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    },

    newLeadAddress(value) {
      this.lead.address = value;
    },

    offerPriceDisplayTermLength(offerPrice) {
      if (offerPrice.recurring) return "Paiement mensuel";

      const termLengthInYears = offerPrice.termLength / 12;
      return `${termLengthInYears} an${termLengthInYears > 1 ? "s" : ""}`;
    },

    offerPriceDisplayPrice(offerPrice) {
      return offerPrice.recurring ? `${formatPrice(offerPrice.price)}/mois` : formatPrice(offerPrice.price);
    },

    offerPriceDisplayReduction(offerPrices, offerPrice) {
      return offerPrice.termLength > 12 ? formatPrice((offerPrices[1].price * offerPrice.termLength) / 12) : "";
    },

    offerPriceComputeReduction(offerPrices, offerPrice) {
      return Math.round(
        (((offerPrices[1].price * offerPrice.termLength) / 12 - offerPrice.price) * 100) / offerPrice.price,
      );
    },

    async getLeadInformations() {
      let response = await LeadService.getInformations(this.$route.params.uniqueToken);
      if (response?.success) {
        this.logoUrl = response.data.store.logoUrl;
        this.brokerLogoUrl = response.data.store.brokerLogoUrl;
        response = response.data.lead;
        this.lead.status = response.status;
        if (this.converted) {
          this.redirectToThankYouPage();
        }
        this.unvalidLead = false;
        this.lead.firstName = response.firstName;
        this.lead.lastName = response.lastName;
        this.lead.birthDate = response.birthDate;
        this.lead.birthCity = response.birthCity;
        this.lead.birthCountry = response.birthCountry;
        this.lead.email = response.email;
        this.lead.phone = response.phone;
        this.lead.address = response.address;
        this.lead.discount = response.discount;
        this.lead.thirdPartyManaged = response.thirdPartyManaged;
        this.productPrice = response.product.price;
        this.offerPrices = response.offerPrices;
        this.category = response.product.category;
        this.marketingPage = response.product.marketingPage;
        this.selectedContractSku = this.offerPrices[0].contractSku;
        this.marketing = response.marketingDetails.marketing;
        this.marketingDetails = response.marketingDetails.marketingDetails;
        this.documents = this.marketing.documents;
      } else {
        this.unvalidLead = true;
        console.error("Erreur lors de la récupération des informations du lead");
      }
    },

    redirectToThankYouPage() {
      this.$router.push("/thank_you");
    },
  },
};
</script>
